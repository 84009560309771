import styled from "styled-components";
import * as React from "react";
import { NavBar } from "../components/ui/";
import { OurTeamSegment, WeAreImprintSegment, WorkHereSegment } from "../components/PageSegments";
import { BottomNavSegment } from "../components/PageSegments/BottomNav";
import { mobileSize } from "../util/variables";
import { BasicLayout } from "../layouts/BasicLayout";

export default ({ data }) => {
  return (
    <BasicLayout>
      <Main>
        <NavBar initiallyWhite />
        <WhiteDiv />
        <WeAreImprintSegment />
        <OurTeamSegment />
        <WorkHereSegment />
        <BottomNavSegment />
      </Main>
    </BasicLayout>
  );
};

const WhiteDiv = styled.div`
  width: 100%;
  height: 140px;
  background-color: transparent;
  @media ${mobileSize} {
    height: 55px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
